.sunday,
.saturday,
.holiday,
.paid {
	td, th {
		background-color: $table-accent-bg;
	}
}

.attend {
	list-style: none;
	padding: 0;
	@include font-size($font-size-sm);
	
	li {
		display: inline-block;
		margin-right: $spacer / 2;
		
		&:not(:last-child) {
			&:after {
				content: "/";
				margin-left: $spacer / 2;
				color: $gray-300;
			}
		}
	}
}

.subordinate {
	.card {
		color: $gray-800;
		background-color: theme-color-level("primary", -10);
		border-color: theme-color-level("primary", -9);
		@include transition($transition-base);
		
		&.active {
			text-decoration: none;
			background-color: theme-color-level("primary", -8);
			border-color: theme-color-level("primary", -7);
		}
		
		&:hover {
			text-decoration: none;
			background-color: theme-color-level("primary", -6);
			border-color: theme-color-level("primary", -5);
		}
		
		&.female {
			background-color: theme-color-level("danger", -10);
			border-color: theme-color-level("danger", -9);
			
			&.active {
				background-color: theme-color-level("danger", -8);
				border-color: theme-color-level("danger", -7);
			}
			
			&:hover {
				background-color: theme-color-level("danger", -6);
				border-color: theme-color-level("danger", -5);
			}
		}
	}
	
	@include media-breakpoint-up(sm) {
		margin-left: -1%;
		margin-right: -1%;
		
		.card {
			margin-left: 1%;
			margin-right: 1%;
			flex: 0 0 48%;
			max-width: 48%;
		}
	}
	
	@include media-breakpoint-up(md) {
		.card {
			flex: 0 0 31.33333%;
			max-width: 31.33333%;
		}
	}
}