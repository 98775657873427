#drawer-toggle {
	display: inline-block;
}

.drawer {
	position: fixed;
	top: 0;
	left: -$drawer-width;
	max-width: 80%;
	width: $drawer-width;
	height: 100vh;
	color: $drawer-color;
	background-color: $drawer-bg;
	@include font-size($drawer-font-size);
	@include box-shadow($box-shadow);
	@include transition($transition-base);
	
	z-index: $drawer-zindex;
	
	a {
		position: relative;
		display: inline-block;
		padding: $nav-link-padding-y $nav-link-padding-x;
		width: 100%;
		color: $drawer-color;
		white-space: nowrap;
		overflow: hidden;
		
		@include hover-focus {
			color: rgba($drawer-color, .8);
			text-decoration: none;
		}
		
		span,
		&.collapse-toggle::after {
			opacity: 1;
			@include transition($transition-fade);
		}
		
		&.collapse-toggle::after {
			position: absolute;
			top: 50%;
			right: $nav-link-padding-x;
			-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%);
		}
	}

	.drawer-open & {
		left: 0;
	}
	
	@include media-breakpoint-up(md) {
		left: 0;
		width: $drawer-width-sm;
		
		a {
			span,
			&.collapse-toggle::after {
				opacity: 0;
			}
		}
		
		.drawer-open & {
			width: $drawer-width;
			
			a {
				span,
				&.collapse-toggle::after {
					opacity: 1;
					
				}
			}
		}
	}
}

.drawer-open {
	overflow-y: hidden;

	@include media-breakpoint-up(md) {
		overflow-y: auto;
	}
}

// Container
.drawer-container {
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	
	ul {
		padding-left: 0;
		list-style: none;
	}
	
	&::-webkit-scrollbar {
		width: .5rem;
	}
	
	&::-webkit-scrollbar-track {
		background-color: $gray-100;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: rgba($gray-600, .5);
		border-radius: .5rem;
		box-shadow: inset 0 0 0 2px $gray-100;
	}
}

// Offset
.drawer-offset {
	@include transition($transition-base);

	@include media-breakpoint-up(md) {
		padding-left: $drawer-width-sm;
		
		.drawer-open & {
			padding-left: $drawer-width;
		}
	}
}

// Backdrop
.drawer-backdrop {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $drawer-zindex - 1;
	width: 100vw;
	height: 100vh;
	background-color: $black;
	opacity: 0;
	@include transition($transition-fade);
	
	// Fade for backdrop
	&.show,
	&.hide {
		display: block;
	}
	&.show { opacity: .5; }
	&.hide { opacity: 0; }
}

// Toggle
.drawer-toggle,
.drawer-toggle-icon
.drawer-toggle-icon::before,
.drawer-toggle-icon::after {
	display: inline-block;
	@include transition($transition-base);
}

.drawer-toggle {
	position: relative;
	padding: $drawer-toggle-padding-y $drawer-toggle-padding-x;
	width: $drawer-toggle-width;
	height: $drawer-toggle-height;
	background: none;
	border: 0;

	&:focus,
	&.focus {
		outline: 0;
	}
}

.drawer-toggle-icon {
	&::before,
	&::after {
		content: '';
		position: absolute;
		left: $drawer-toggle-padding-x;
		width: $drawer-toggle-width - ($drawer-toggle-padding-x * 2);
		height: $drawer-toggle-border-width;
		background-color: $drawer-toggle-border-color;
	}

	&::before {
		top: ($drawer-toggle-height / 2) - $drawer-toggle-padding-y;
	}

	&::after {
		top: ($drawer-toggle-height / 2) + ($drawer-toggle-padding-y / 2);
	}
}

// Colapse
.collapsible {
	position: relative;
}

.collapsible-menu {
	@include font-size($font-size-sm);

	.nav-link {
		padding: $spacer / 2 0;
	}

	.drawer-open & {
		padding-left: $drawer-width-sm;
	}
}

@include media-breakpoint-up(md) {
	body:not(.drawer-open) {
		.collapsible {
			@include hover-focus {
				.collapsible-menu {
					display: block;
				}
			}
		}

		.collapsible-menu {
			position: absolute;
			top: 0;
			left: $drawer-width-sm + ($spacer / 2);
			padding: $dropdown-padding-y 0;
			min-width: $dropdown-min-width;
			background-color: $dropdown-bg;
			border: $dropdown-border-width solid $dropdown-border-color;
			@include border-radius($dropdown-border-radius);
			@include box-shadow($dropdown-box-shadow);
			
			@include hover-focus {
				display: block;
			}

			.nav-link {
				padding: $dropdown-item-padding-y $dropdown-item-padding-x;

				@include hover-focus() {
					color: $dropdown-link-hover-color;
					text-decoration: none;
					@include gradient-bg($dropdown-link-hover-bg);
				}
			}
		}
	}
}