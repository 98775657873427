.avatar {
	position: relative;
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	vertical-align: middle;
	background: $gray-300 url("../img/avatar.png") no-repeat center;
	background-size: cover;
	@include border-radius($border-radius);
}

.navbar {
	margin-bottom: $spacer;
	position: sticky;
	top: 0;
	z-index: $zindex-fixed;
	background-color: $white;
	@include font-size($font-size-sm);
	
	.breadcrumb {
		@include font-size($font-size-lg);
		
		.breadcrumb-item {
			a {
				color: $breadcrumb-active-color;
				
				@include hover-focus() {
					text-decoration: none;
				}
			}
			
			&.active {
				font-weight: $font-weight-bold;
				color: $theme-color;
			}
		}
	}
}

.navbar-nav {
	align-items: center;
	
	.dropdown-toggle {
		color: $navbar-light-color;
		
		&::after {
			content: none;
		}
		
		@include hover-focus() {
			box-shadow: 0;
		}
	}
	
	.dropdown-header {
		text-align: center;
	}
	
	.dropdown-menu {
		left: auto;
		right: 0;
		@include font-size($font-size-sm);
	}
}

.footer {
	padding: $spacer $grid-gutter-width;
	border-top: solid $border-width $border-color;
	color: $text-muted;
	@include font-size($font-size-sm);
}

//
// Main
//

.main {
	padding: 0 $grid-gutter-width;
}

.sec {
	+ .sec {
		margin-top: $spacer * 2;
	}
}

.sec-title {
	margin-bottom: $spacer;
	position: relative;
	font-weight: $font-weight-bold;
	@include font-size($font-size-lg);
	
	&::before {
		content: '';
		display: inline-block;
		margin: {
			top: $spacer / 2;
			right: $spacer / 2;
		};
		width: $grid-gutter-width;
		height: 8px;
		float: left;
		background-color: theme-color("primary");
		@include border-radius(5px);
	}
}

.sec-subtitle {
	margin-bottom: $spacer;
	color: $text-muted;
	font-weight: $font-weight-bold;
	@include font-size($font-size-base);
}

.sec-lead {
	color: $text-muted;
	
	.sec-title + & {
		margin-top: -$spacer / 2;
		padding-left: calc(#{$grid-gutter-width} + #{$spacer / 2});
	}
}

//
// Sidebar
//

.navigation {
	.avatar {
		margin-right: .25rem;
		width: 1.25rem;
		height: 1.25rem;
		background-color: rgba($white, .3);
	}
	
	.nav-tabs {
		flex-wrap: nowrap;
		border-bottom: 0;
		
		.nav-item {
			margin-bottom: 0;
		}
		
		.nav-link {
			padding: {
				top: $spacer;
				bottom: $spacer;
			}
			color: rgba($drawer-color, .3);
			text-align: center;
			border: 0;
			border-radius: 0;
			
			&.active {
				color: $drawer-color;
				background-color: transparent;
			}

			&::before {
				@include font-size($font-size-base);
			}
		}
	}
	
	.tab-pane {
		padding: $spacer / 2 0;
		
		a {
			color: inherit;
		}
		
		.collapse-toggle {
			font-weight: $font-weight-bold;
		}
		
		&.tab-menu {
			ul {
				ul {
					margin-left: $spacer * 2;
				}
			}
			
			[class*="fa-"] {
				margin-right: .5rem;
			}
		}

		&.tab-tree {
			a {
				padding: {
					top: $nav-link-padding-y / 2;
					bottom: $nav-link-padding-y / 2;
				}
			}

			.tree {
				.phone {
					margin-left: .25rem;
				}
			}

			ul {
				ul,
				ul .tree {
					margin-left: $spacer;
				}
			}
		}
	}
}

//
// Class
//

.nav-fill {
	.nav-item {
		color: $gray-600;
		border-bottom: solid 2px $gray-200;
		
		@include hover-focus() {
			color: $body-color;
		}
		
		&.active {
			font-weight: $font-weight-bold;
			color: theme-color("primary");
			border-bottom-color: theme-color("primary");
		}
	}
}

//
// Icon
//

.aaaaaaaa::before {
	display: inline-block;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	color: inherit;
	-webkit-font-smoothing: antialiased;
}

.collapse-toggle {
	white-space: nowrap;
	@include caret;
}

[class*="fa-"] {
	width: 1.25em;
	text-align: center;
	vertical-align: middle;
}

.navbar-nav,
.navigation {
	[class*="fa-"] {
		@include font-size($font-size-lg);
	}
}

.navbar-nav .dropdown-menu {
	[class*="fa-"] {
		@include font-size($font-size-base);
	}
}